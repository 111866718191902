import * as React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import "../../styles/main.css";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import Service from "../../components/Service";
import img from "../../images/services/Pulizie-Uffici-Manutenpul.webp";
import FloatButton from "../../components/FloatButton";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};


// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul | Pulizia Uffici</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie, impresa di pulizie cesano boscone,impresa di pulizie legnano, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Pulizie Uffici & Aziende" prepageName="Servizio" postpageName="Pulizie Uffici & Aziende" />
      <Service
        title="Pulizia Uffici"
        text="
        MANUTENPUL effettua servizi di pulizia professionale, rivolti alla manutenzione igienica di ambienti con basso tenore di sporco che necessitano di un elevato standard di igiene, come uffici e le aziende.
        Giacche gli uffici sono ambienti di lavoro in cui trascorriamo molto tempo, 
        rappresentano quasi la nostra seconda casa, per cui è assolutamente necessario 
        procedere ad accurate pulizie degli uffici."
      img={img}
      children=' MANUTENPUL si occupa da anni con 
      professionalità e scrupolosità delle pulizie degli uffici, affinché i lavoratori possano 
      lavorare in un ambiente pulito e gradevole.
      Realizziamo servizi di pulizie uffici offrendo oltre ad interventi di igienizzazione generali degli spazi, anche servizi di logistica e Fornitura di Materiale Economato. Adattandoci 
      alle necessità del committente, siamo in grado di fornire servizi di pulizie uffici perfetti, 
      pulendo tutti gli arredi ed i mobili delle varie stanze in modo veloce ad accurato.'
      />

      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
